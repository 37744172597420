/* General Settings CSS */
.tableContainer {
  //height: 650px;
  width: 100%;
  padding: 0 1.5rem 1.5rem 1.5rem;
}
.MuiDataGrid-columnHeaders {
  background-color: #f1f1f1;
  color: rgb(89, 89, 89);
  font-family: "Arial";
}

.containerStyle {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  border-bottom: '1px dashed #d9dcde'
}

.MuiDataGrid-root {
  border: none !important;
}
.MuiDataGrid-footerContainer {
  border: none !important;
}

.MuiDataGrid-main {
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  font-size: 0.75rem;
  height: 50vh;
}


/* default styles here for older browsers. 
       I tend to go for a 600px - 960px width max but using percentages
    */

@media only screen and (min-width: 960px) {
  /* styles for browsers larger than 960px; */
  .MuiDataGrid-main {
    height: 54vh;
  }
  .bpx-home-block .MuiDataGrid-main {
    height: 70vh;
  }

  .bpx-chart-block {
    height: 100%;
  }
}

@media only screen and (min-width: 1500px) {
  /* styles for browsers larger than 1440px; */
  .MuiDataGrid-main {
    height: 58vh;
  }
  
}

@media only screen and (min-width: 1800px) {
  /* for sumo sized (mac) screens */
  .MuiDataGrid-main {
    height: 63vh;
  }
  
}


.block-lines-top .MuiDataGrid-main {
    min-height: 109px;
}

@media only screen and (max-device-width: 480px) {
    /* styles for mobile browsers smaller than 480px; (iPhone) */
}
@media only screen and (device-width: 768px) {
    /* default iPad screens */
}
/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  /* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
  /* For landscape layouts only */
}
