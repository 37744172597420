.bpx-userid-title.MuiDialogTitle-root {
  margin: 0;
  text-align: center;
  font-weight: 700;
  line-height: normal;
  font-size: 1.4rem;
  font-family: "Arial Regular";
}

.bpx-userid-name {
  font-size: 18px;
  width: 40%;
  padding-top: 10px;
}

.bpx-userid-password-name {
  font-size: 18px;
  width: 60%;
  padding-top: 10px;
}

.bpx-userid-section {
  display: flex;
  justify-content: space-between !important;
  //align-items: center;
  gap: 10px;
  padding: 0.5rem 0;
}



.bpx-userid-field {
  width: 60%;

  .MuiFormControl-root, .bpx-userid-select {
    width: 250px;
  }

}

.bpx-userid-field-new-role {
  width: 73%;

  .MuiFormControl-root, .bpx-userid-select {
    width: 250px;
  }

}


.bpx-userid-name-new-role {
  font-size: 18px;
  width: 27%;
  padding-top: 10px;
}

.bpx-userid-field-autocomplete {
  width: 73%;
  .MuiFormControl-root, .bpx-userid-select {
    width: 400px;
  }
}

.bpx-userid-modal {
  //min-width: 450px;
  min-height: 250px;
  padding: 0.5rem 0;
  font-family: "Arial Regular";
}


button:first-child.bpx-button-toolbar {
  margin-right: 1rem;
}


.bpx-wrap-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  margin-top: 14%;
  padding: 0 20px;
}

.bpx-auth-section-past-row {
  color: #D3D3D3;
}




// List
.bpx-auth-list {
  display: flex;
  flex-flow: row wrap;
  font-family: "Arial Regular";
  line-height: normal;
  padding-bottom: 1rem;
  padding-left: 2rem;
}

.bpx-auth-section {
  display: flex;
  width: 40%;
  padding: 0.3rem 0;
}

.bpx-auth-left {
  margin-right: .5rem;
  font-weight: bold;
}

.bpx-auth-section-pass-row{
  color: #D3D3D3
}

input:-webkit-autofill,
.MuiInputBase-root:has(> input:-webkit-autofill) {
  background-color: rgb(232, 240, 254);
  margin-right: 20px;
}

// .MuiDialogContent-root {
//   padding-top: 5px !important;
// }


.bpx-auth-warning {
  color: #ffcc00
}