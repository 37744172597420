.breadCrumbsBlock {
  margin: 0 !important;
  font-size: 12px !important;
}
.breadCrumbsBlock li {
  color: #000;
  font-family: "Montserrat" !important;
}
.breadCrumbsBlock .MuiBreadcrumbs-separator {
  line-height: 0;
  font-weight: 500;
  font-style: italic;
}
.breadCrumbsBlock .MuiBreadcrumbs-li {
  font-style: italic;
  font-weight: 500;
  color: black;
}
.breadCrumbsBlock li a, .breadCrumbsBlock li p {
  color: #000;
  font-weight: 500;
  font-family: "Montserrat" !important;
  font-size: .75rem;
  letter-spacing: normal;
  margin-right: 5px;
  text-transform: capitalize;
  text-decoration: none;
  position: relative;
} 

.breadCrumbsBlock li p {
  margin-right: 0 !important;
}

.breadCrumbsBlock li a:hover {
  text-decoration: underline;
}

.breadCrumbsBlock li:first-child a::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 9px;  
  // margin-right: 8px;
  margin-left: 8px;
  background: url(../../assets/images/arrow.svg) no-repeat center center;
  background-size: cover;
}


.breadCrumbLineHr {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
