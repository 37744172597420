.subtotal-accordion {
  border-radius: 0 !important;
  box-shadow: none !important;

  .MuiAccordionSummary-root {
    min-height: inherit !important;
    padding-left: 0 !important;
    border-bottom: 1px solid #f1f1f1;
    font-family: "Arial Regular";
    color: #595959;
    font-size: 12px;
  }
  .MuiAccordionSummary-content {
    margin: 0 !important;
  }
  .MuiAccordionDetails-root {
    padding: 0 !important;
  }


}

.subtotal-filter-block {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
}

.subtotal-filter-section {
  width: 15%;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  font-family: "Arial Regular" !important;
  color: #595959 !important;
  font-size: 12px !important;
  display: flex;
  flex-flow: row wrap;
  margin-top: 0.7rem;
}
.subtotal-filter-label {
  margin-right: 8px;
  margin-bottom: 5px;
  width: 100%;
}
.subtotal-filter-field {
  width: 100%;


   .MuiOutlinedInput-root {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
    .MuiOutlinedInput-input {
      width: 100% !important;
      font-family: "Arial Regular" !important;
      color: #595959 !important;
      font-size: 12px !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-left: 5px;
      padding-right: 5px;
    }
    .MuiFormControl-root {
      width: 100%;
    }
    .MuiIconButton-edgeEnd {
      padding: 0;
      margin-right: -8px;

        .MuiSvgIcon-root {
          font-size: 1.2rem;
        }
    }
    .MuiOutlinedInput-root.trade-filter{
      height: 27px;
      padding-left: 9px;
    }
    .trade-filter .MuiInputBase-root{
      padding-left: 9px;
    }
}



.subtotal-filter-textfield {
      
  .MuiAutocomplete-popupIndicator {
    padding: 0;
  }
  .MuiSvgIcon-root {
    font-size: 1.2rem;
  }
  .MuiAutocomplete-endAdornment {
    right: 5px !important;
  }

  .MuiChip-label {
    padding-left: 6px;
    padding-right: 6px;
    font-family: "Arial Regular" !important;
    color: #595959 !important;
    font-size: 12px !important;
  }

  .MuiChip-deleteIcon {
    font-size: 1rem !important;
    margin-right: 3px !important;
  }

}

.subtotal-filter-list {
  font-family: "Arial Regular" !important;
  color: #595959 !important;
  font-size: 12px !important;
  display: flex;
  align-items: center; 
}

.subtotal-filter-no-multiple{
  cursor: pointer;
  line-height: normal !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 0.7rem;
  width: 120px;
  padding: 7px 0;
  font-weight: 700;
}

.subtotal-filter-checkbox {
  line-height: normal !important;
  display: flex;
  cursor: pointer;
    .subtotal-filter-name {
      font-weight: 700;
    }
    .subtotal-filter-dots {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-left: 0.3rem;
      width: 120px;
    }
}


.subtotal-autoselect-list {
  font-size: 12px;
  font-family: "Arial Regular";
  display: flex;  
  padding: 0.3rem 0.5rem;

    &.selected {
      background-color: #f1f1f1;
    }
    &:hover {
      background-color: #f1f1f1;
    }

    .subtotal-autoselect-checkbox {
      display: flex;
      cursor: pointer;
    }
    .subtotal-autoselect-name {
      margin-right: 3px;
      font-weight: bold;
    }
    .subtotal-autoselect-dots {
      
    }

}