
.bpx-home-container {
  padding: 0 1.5rem 1.25rem 1.5rem;
  min-height: 500px;
}

.bpx-home-block {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
}
.bpx-home-separator {
  width: 49%;
  height: 100%;
}

.bpx-home-section {
  width: 100%;
  margin-top: 1rem;
}

.table-cell-trucate {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  width: 380px;
}




.bpx-home-toolbar {
  padding-bottom: 5px;
  line-height: 1.43;

    &:after {
      content: '';
      display: block;
      border-bottom: 2px solid #e6e6e6;
    }

}

.bpx-home-toolbar-inner {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  align-items: center;
  padding-bottom: 2px;

    p {
      font-size: 14px;
      font-family: 'Montserrat';
      font-weight: 500;
      margin: 0;
    }

}


.bpx-home-toolbar-dots {
  cursor: pointer;
}


.bpx-chart-block {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  gap: 10px;
}

.bpx-chart-highlights-boxes{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, calc((100% - 60px)/4));
  gap: 20px;
}


.bpx-home-inner {
  // display: flex;
  // flex-direction: column;
  // gap: 60px
  // margin-bottom: 20px;  
  padding: 0.3rem;
}

.bpx-chart-drop-box {

  .MuiInputLabel-root {
    font-size: 0.75rem !important;
  }

  .MuiOutlinedInput-root {
    font-size: 0.75rem !important;
    width: 100% !important;
  } 
}

.bpx-chart-drop-box-item {
  font-size: 0.75rem !important;
}

.bpx-chart-drop-box-item-multiple {
  font-size: 0.75rem !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}


.bpx-chart-left {
  width: 57%;
}

.bpx-chart-right {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 42%;
}

.bpx-chart-section {
  display: flex;
  text-align: center;
  align-items: center;
  font-family: 'Arial';
  margin-bottom: 8px;

    p {
      margin: 0;
    }
}
.bpx-chart-inner {
  width: 100%;
  height: 100px;
  padding: 5px;
  background-color: #f1f1f1;
}

.bpx-chart-title {
  // min-height: 35px;
  margin-bottom: 5px;
  line-height: 16px;
  
    p {
      font-size: 14px;
      padding-top: 0.5rem;
    }
}
.bpx-chart-stat {
  padding: 10px;
  
    p {
      font-size: 30px;
      line-height: normal;
    }
}
.bpx-chart-stat.red-stat {
  color: #ff0000;
}
.bpx-chart-stat.green-stat {
  color: #00a651;
}
.bpx-chart-stat.orange-stat {
  color: #f7941d;
}

.mockup-loading img{
  width: 100%;
  height: auto;
}

.periodCurrency {
  margin-left: 10px;
  display: inline-block;
  padding-top: 3px;
  font-size: 12px;
}

.adjustment-summary {
  font-size: 12px;
  width: fit-content; 
  background-color: #f1f1f1;
}

.apexcharts-xaxis-label{
  display: none;
  &:first-child {display:revert;}
  &:nth-child(2n+1){ display:revert; }
}

.bpx-chart-drop-box-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 25px 10px 10px;
}

.bpx-chart-drop-box {
  width: 30%
}


.bpx-chart-plan-account-container {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.bpx-chart-plan-account {
  background-color: #f1f1f1;
  border-radius: 4px;
}


@media only screen and (max-width: 1200px) {
  .bpx-chart-highlights-boxes{
    grid-template-columns: repeat(2, calc((100% - 20px)/2));
  }
}

@media only screen and (max-width: 600px) {
  .bpx-chart-highlights-boxes{
    grid-template-columns: 100%;
  }
}


.wrapCell {
  word-break: break-word;
  white-space: break-all;
  overflow-wrap: break-all;
  text-align: right;
}