.customSelectTable {
  font-size: 0.75rem !important;
}
.customSelectTable .MuiSelect-select {
  display: flex;
  padding-top: 1.5px !important;
  padding-bottom: 1.8px !important;
  align-items: center;
}
.customSelectTable.Mui-focused .MuiSelect-select {
  border-color: #f69e80 !important;
}

.customOptionTable {
  font-size: 0.75rem !important;
}
.customLabelTable {
  font-size: 0.75rem !important;
  -webkit-transform: translate(16px, 4.5px) scale(1) !important;
    -moz-transform: translate(16px, 4.5px) scale(1) !important;
    -ms-transform: translate(16px, 4.5px) scale(1) !important;
    transform: translate(16px, 4.5px) scale(1) !important;
}
.customLabelTable.MuiFormLabel-filled {
   opacity: 0;
}
.customLabelTable.Mui-focused {
  color: #f69e80 !important;
}
.customSelectTable.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #f69e80 !important; 
}

.customSelectTable .MuiSelect-iconOutlined {
  right: 1.8px;
  background-color: #e1e1e1;
  color: #fff;
  border-radius: 0 3px 3px 0;
  top: calc(50% - 0.656rem);
  height: 21px;
  width: 21px;
}

.customSelectTable .MuiSelect-iconOpen.MuiSelect-iconOutlined {
  background-color: #f69e80;
  border-radius: 3px 0px 0px 3px;
}


.custom-link-table .MuiSvgIcon-root {
  filter: invert(73%) sepia(50%) saturate(7284%) hue-rotate(339deg) brightness(101%) contrast(86%);
  transform: rotate(90deg);
  right: 2px;
}

.custom-link-table .MuiSelect-select[aria-expanded="true"] ~ .MuiSvgIcon-root {
  transform: rotate(-90deg);
}

