.page_404 { 
  padding:40px 0; 
  background:#fff; 
  text-align: center;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.page_404  img{ 
  width:100%;
}

.four_zero_four_bg{
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  background-repeat: no-repeat;
  height: 400px;
  background-position: center;
  margin-top: 1rem;
 }
 
 
.four_zero_four_bg h1{
  font-size: 50px;
}
 
.four_zero_four_bg h3{
  font-size:80px;
}

.bpx-lost-text {
  margin: 0;
}

.bpx-sub-text {
  margin: 0;
}
       
.link_404{      
  color: #fff!important;
  padding: 10px 20px;
  background: #1976d2;
  margin: 0.5rem 0;
  display: inline-block;
  text-decoration: none;
  border-radius: 5px;
}

.contant_box_404{ 
  margin-top:-50px;
}
