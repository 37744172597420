.bpx-access-info-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}

// Dialog
.bpx-dialog-status-bg {

    .MuiDialog-paper {
      min-width: 400px !important;
      min-height: 160px;
      font-family: 'Arial';
      padding: 0.3rem 0 1rem 0;
    }
    .bpx-dialog-status-header {
      position: relative;
      border-bottom: 1px solid black;
      padding-bottom: 0.2rem;
      padding-top: 0.2rem;
    }
    .bpx-dialog-status-title {
      font-weight: bold;
      text-align: center;
      font-size: 18px;
    }
    .bpx-dialog-status-close {
      position: absolute;
      right: 3px;
      top: 3px;
      cursor: pointer;
      height: 24px;
    }

    .bpx-dialog-status-content {
      padding: 1rem;
      min-height: 70px;
    }
    .bpx-dialog-status-text {
      text-align: center;
    }

    .bpx-dialog-status-footer {
      display: flex;
      justify-content: center;

       
        button:first-child {
          margin-right: 0.6rem;
        }
        

    }
} 
