$colorText: rgb(89, 89, 89);
$colorBorder: #f1f1f1;
$ArialRegular: "Arial Regular";
$fontSize: 0.75rem;

.bpx-block-detail {
  min-height: 500px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  color: $colorText;
  line-height: initial;
  padding-bottom: 50px;
  font-family: $ArialRegular;
  font-size: $fontSize;
}

.bpx-title-detail {
  font-family: 'Montserrat';
  font-size: 14pt;
  font-weight: 500;
  margin: 30px 0;
}

.bpx-left-side {
  width: 30.55%;
}

.bpx-section-detail {
  margin-bottom: 25px;
  line-height: initial;
}
.bpx-top-detail {
  padding: 10px 10px 10px 2.7%;
  background-color: #f1f1f1;
  border-radius: 4px;
}
.bpx-field-detail {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 12px;

    &:first-child {
      margin-top: 10px;
    }
    &.bpx-field-margin {
      margin-top: 35px;
    }
}
.bpx-align {
  align-items: center;
}
.bpx-key-detail {
  width: 45.5%;
  padding-left: 5%;

   p {
     margin: 0;
   }
}

.bpx-editable-detail {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;


    .MuiOutlinedInput-input {
      width: 160px;
      border-radius: 1px;
      padding-left: 5px;
      padding-top: 0;
      padding-bottom: 0;
      box-sizing: border-box;
      height: 18px;
      font-size: $fontSize;
      font-family: $ArialRegular;
    }
    .bpx-edit-icon, .bpx-tooltip-icon {
      height: 15px;
      width: 15px;
      font-size: initial;
      cursor: pointer;
      margin-right: 6px;
      color: #5d5d5d;
    }
    .bpx-edit-icon-lock {
      cursor: pointer;
      margin-right: 6px;
      color: #5d5d5d;
      width: 1.2rem;
      height: auto;
    }
    svg:last-child.bpx-edit-icon-lock {
      margin-right: 0;
      width: 1.3rem;
    }
    .bpx-tooltip-icon {
      color: #599cdf;
      margin-right: 0;
    }
    .bpx-icon-section {
      display: flex;
      margin-right: 5%;
    }
    

}
.bpx-value-detail {
  width: 54.5%;


  p {
    margin: 0;
    padding-left: 5px;
  }

}

.bpx-value-detail-dropdown {
  display: flex;
  align-items: center;

  .bpx-icon-trashcan {
    font-size: 1.4rem;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  
}

.bpx-right-side {
  width: 65.5%;

   .MuiAccordion-root {
     border: none;
     box-shadow: none;
     color: inherit;

      &::before {
        content: none;
      }
   }
   .MuiAccordion-root.Mui-expanded {
     margin: 0;
   }

}

.bpx-accordion-header {
  min-height: initial !important;
  padding: 10px 10px 3px 0 !important;
  border-bottom: 1px solid $colorBorder !important;
  flex-direction: row-reverse;

    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0;
    }
    p {
      margin: 0;
    }
    .MuiAccordionSummary-content {
      margin: 0;
    }
    .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
      transform: rotate(90deg);
    }
    .bpx-arrow-icon {
      width: 1.2rem;
      height: 1.2rem;
      color: #000;
    }
    .MuiAccordionSummary-expandIconWrapper {
      margin-right: .5rem;
    }
}

.bpx-accordion-detail {
  padding: 23px 0 10px 3.4% !important;

    p {
      margin: 0;
    }
}

.bpx-modal-find-product .bpx-accordion-detail {
  padding-right: 3.4% !important;
}

.bpx-section-accordion-detail {
  margin-bottom: 15px;

  .bpx-top-detail {
    padding: 0;
    background-color: #fff;
    font-weight: 600;
  }
  .bpx-key-detail {
    width: 23.5%;
    padding-left: 1.2%;
    margin-right: 1%;
  }
  .bpx-value-detail {
    width: initial;
  }
  .bpx-field-detail {
    margin-bottom: 8px;
  }
  .bpx-input-section {
    margin-right: 12px;
  }

}


.modal-rates-container {

  .MuiPaper-root.MuiPaper-elevation {
    width: 85%;
    min-height: 250px;
    max-width: initial;
  }
}

.modal-rates-block {
  width: 100%;
  padding: 1rem;
}

.modal-rates-header {
  position: relative;

    .modal-rates-title {
      font-family: "Arial Regular";
      font-weight: 500;
      font-size: 1.25rem;
      border-bottom: 1px solid black;
      padding-bottom: 0.3rem;
    }
    .modal-rates-close {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
    }
}

.modal-rates-content-block {
  padding-top: 2rem;
}
.modal-rates-content {
  display: flex;
  // flex-flow: row wrap;
  align-items: center;
  margin-bottom: 2rem;

    .modal-rates-section {
      width: 320px;
      margin-right: 1.5%;
      margin-left: 1.5%;
    }
    .MuiFormControl-root {
      width: 100%;
    }

    .modal-rates-rememotext {
      font-size: 1.2rem;
    }
    

}

.modal-rates-content-remove {
  justify-content: center;
}

.modal-rates-footer {
  display: flex;
  justify-content: flex-end;

    button {
      margin-right: 1rem;
    }
    button:last-child {
      margin-right: 0;
    }
    
}


.bpx-group-block {
  display: flex;
  align-items: center;

    .bpx-group-left {
      margin-right: 0.5rem;

        button {
          box-sizing: border-box;
          -webkit-tap-highlight-color: transparent;
          background-color: transparent;
          outline: 0;
          border: 0;
          margin: 0;
          cursor: pointer;
          user-select: none;
          vertical-align: middle;
          appearance: none;
          text-decoration: none;
          text-align: center;
          flex: 0 0 auto;
          border-radius: 50%;
          overflow: visible;
          color: #000;
          transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          padding: 5px;
          font-size: 1.125rem;
          height: 28px;

             &:hover {
              background-color: rgba(0, 0, 0, 0.04);
             }
        }

        svg {
          user-select: none;
          width: 1em;
          height: 1em;
          display: inline-block;
          fill: currentcolor;
          flex-shrink: 0;
          transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          font-size: inherit;
        }

    }
}

.bpx-group-count {
  margin-left: 0.5rem;
}




.bpx-btn-rates-add, .bpx-btn-rates-check {
  padding: 0 !important;
  margin: 0 !important;
  min-width: initial !important;
  height: 30px !important;
  width: 30px !important;
  color: #000 !important;
}
.bpx-btn-rates-add {
  margin-right: 0.5rem;
}
.bpx-btn-rates-add:disabled svg{
  color: rgba(0, 0, 0, 0.26);
}

.bpx-mr-05 {
  margin-right: 0.5rem !important;
}
.bpx-padding {
  padding: 0 1rem !important;
}

.modal-rates-container-delete {

    .MuiPaper-root.MuiPaper-elevation {
      width: 30%;
      min-height: auto;
    }
    .modal-rates-rememotext {
      margin-top: 2rem;
    }
}

.bpx-popupdialog-bg {
  

    .MuiPaper-elevation.MuiDialog-paper {
      padding: 1rem;
      min-width: 500px;
    }
}

.bpx-popupdialog-block {
  text-align: center;
  font-family: "Arial Regular";

  .bpx-popupdialog-header {
    position: relative;
    border-bottom: 2px solid black;
    padding-bottom: 0.5rem;
  }
  .bpx-popupdialog-name {
    font-size: 1.25rem;
  }
  .bpx-popupdialog-closeicon {
    position: absolute;
    right: -3px;
    top: 3px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);
  }
  .bpx-popupdialog-content {
    padding-bottom: 3rem;
  }
  .bpx-popupdialog-footer {
    text-align: right;

      .bpx-popupdialog-btn {
        margin-right: 0.5rem;
      }
      button:last-child.bpx-popupdialog-btn {
        margin-right: 0;
      }
  }
  
}
