.bpx-active-menu {
  background-color: rgba(0,0,0, 0.1) !important;
  border-radius: initial !important;
}

.bpx-btn-header {
  color: black !important;
  padding: 10px !important;
  text-transform: none !important;
  font-family: "Montserrat" !important;
  text-underline-offset: 2px !important;
}
