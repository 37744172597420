$speed: all 0.3s;

.bg-help {

    .MuiDialog-paper {
      background-image: url('../../assets/images/Dino.png');
      background-repeat: no-repeat;
      background-position: 90% 96%;
      background-size: 57%;
    }
  
}

.block-help {
  padding: 1rem 2rem;

    .block-header-help {
      font-size: 1.188rem;
      text-align: center;

        span {
          color: #f7941d;
        }

        &:after {
          content: '';
          display: block;
          border-bottom: 1.5px solid #000;
          padding-top: 0.5rem;
        }
    }

    .block-middle-help {
      padding-left: 1rem;
      font-family: "Arial Regular";
      font-size: 0.875rem;
    }

    .block-bottom-help {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;        
    }

    .section-help {
      border: 1px solid #1976d2;
      border-radius: 5px;     
      display: block;
      text-align: center;
      text-decoration: none;
      width: 42%;
      height: 135px;
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: $speed;

        &:hover {
          border-color: #f7941d;

            .section-icon-help {
              color: #f7941d;
            }
            .section-text-help {
              color: #f7941d;
            }

        }
    }
    a:link,
    a:visited,
    a:hover,
    a:active {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.87);
    }
    
   

    .section-icon-help {
      color: #1976d2;
      transition: $speed;
      
        svg {
          font-size: 2rem;
        }
    }

    .section-text-help {
      color: #000;
      padding: 0 1rem;
      font-size: 0.875rem;
      transition: $speed;
    }

}