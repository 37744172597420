.bpx-totaltable-block {

  h3 {
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
  }
}

.bpx-totaltable-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border-top: 1px solid #f1f1f1;

    .bpx-totaltable-left {
      width: 22.8%;
      padding-top: 38px;
    }
    .bpx-totaltable-right {
      width: 77.2%;
    }

    .bpx-totaltable-reportyear {
      font-weight: bold;
      font-size: 0.78rem;
    }
    .bpx-totaltable-lastrefresh {
      font-weight: bold;
      margin-top: 0.5rem;
      font-size: 0.78rem;
      display: flex;
    }
    .bpx-totaltable-lastrefreshDate {
      margin-left: 0.3rem;
    }
}


// Modal window
.bpx-refresh-bg {

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    min-width: 400px;
  }

}
.bpx-refresh-block {
  padding: 0.5rem 1rem 1.2rem 1rem;

  .bpx-refresh-header {
    position: relative;
    border-bottom: 1px solid #000;
    padding-bottom: 0.2rem;
  }
  .bpx-refresh-title {
    font-size: 1.2rem;
  }
  .bpx-refresh-close {
    position: absolute;
    top: 0.2rem;
    right: 0;
    cursor: pointer;
  }

}

.bpx-refresh-content {

  .bpx-refresh-center {
    text-align: center;
    margin-top: 0.5rem;
    font-size: 1.1rem;
  }
  .bpx-refresh-line {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
  }
  .bpx-refresh-line-left {
    margin-right: 0.7rem;
    font-size: 1.1rem;
  }
  .bpx-refresh-line-right {

  }
  .modal-rates-section {
    width: 170px;
  }

}

.bpx-refresh-footer {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;

    button {
      width: 100px;
    }
    .bpx-refresh-cancel {
      margin-right: 1rem;
    }
    .bpx-info-footer {
      width: 100%;
      text-align: center;
      margin-bottom: 0.5rem;
    }

}

.bpx-refresh-loading-content {
  text-align: center;
  padding: 1rem;

    .bpx-refresh-loading {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    .bpx-refresh-loading-text {
      font-weight: bold;
    }
    
    .bpx-refresh-clearStorage-text {
      font-style: italic;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
}