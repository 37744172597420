.block-menu-toolbar {
  
  .MuiList-root {
    padding: 0.2rem;
  }
  .section-menu-toolbar {
    display: flex;
    align-items: center;
  }
  .text-menu-toolbar {
    font-size: 14px;
  }
  .icon-menu-toolbar {
    height: 1.2rem;
    margin-right: 0.5rem;
    
      svg {
        font-size: 1rem;
      }
  }


}

.bpx-home-section {
  position: relative;
  z-index: 5;
}
.dragging {
  border: 3px solid #2f5597;
  cursor: grab;
}

[draggable] {
  user-select: none;
}

