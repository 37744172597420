.bpx-select-item {
  color: rgb(246, 158, 128);
  text-decoration: underline;
  font-weight: bold;
}

.bpx-select-item.abort {
  color: #ff0000;
}
.bpx-select-item.cancel {
  color: #1c1e21;
  padding-right: 1rem;
  font-weight: bold;
}
.bpx-select-item.cancel:disabled,
.bpx-select-item.cancel[disabled] {
  color: rgba(0, 0, 0, 0.26);
  cursor: auto
}
.block-cancel-modal {
  width: 300px;
  padding-bottom: .5rem;
  color: #727171;
  font-family: "Arial Regular";
}

.header-cancel-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  border-bottom: 1px solid #ccc;
  padding: 0.3rem 10px;

    .name-cancel-modal {
      font-size: .9rem;
    }
    .close-cancel-modal button {
      padding: 0;

        svg {
          font-size: 1.3rem;
        }
    }

}

.content-cancel-modal {
  text-align: center;
  min-height: 60px;
  padding-top: .5rem;
  font-size: .9rem;
}

.action-cancel-modal {
  padding: .3rem 2rem;
}

.section-cancel-modal {
  display: flex;
  justify-content: space-between;

    button {
      min-width: 80px;
      min-height: 25px;
      border-radius: 3px;
      cursor: pointer;
    }
    button.cancel {
      background-color: transparent;
      border: 1px solid #ccc;
      color: #3c3b39;
    }
    button.OK {
      background-color: #65bff2;
      border: 1px solid #65bff2;
      color: #fff;
    }


}



.bpx-dialog-accstatus-bg {

  .MuiPaper-elevation.MuiDialog-paper {
    min-width: 650px;
  }
}

.bpx-dialog-accstatus-block {
  padding: 1rem;
}

.bpx-dialog-accstatus-header {
  border-bottom: 2px solid #ccc;
  position: relative;
  padding-bottom: 0.5rem;

  .bpx-dialog-accstatus-title {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
  }
  
  .bpx-dialog-accstatus-close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    height: 24px;
  }

}



.bpx-dialog-accstatus-content {
  
}
.bpx-dialog-accstatus-section {
  border: 1px solid black;
  border-radius: 20px;
  padding: 0.5rem;
  margin-top: 1rem;

  div:first-child.bpx-dialog-accstatus-inline {
    margin-bottom: 0.5rem;
    font-size: 18px; 
  }
  .bpx-dialog-accstatus-inline {
    margin-bottom: 0.3rem;
  }

}

.bpx-dialog-accstatus-inline {
  display: flex;

    .bpx-dialog-accstatus-left {
      width: 55%;
    }

    .bpx-dialog-accstatus-right {
      width: 45%;
    }

    p {
      margin: 0; 
      font-weight: bold;
    }
}

.bpx-dialog-accstatus-buttons {
  text-align: right;
  margin-top: 1rem;

    button:last-child {
      margin-left: 1rem;
    }
}

.bpx-dialog-accstatus-option {

  .MuiPaper-elevation.MuiDialog-paper {
    min-width: initial;
    min-height: 200px;
    padding: 0 1rem 0 1rem;
    width: 550px;
  }
  .bpx-dialog-accstatus-header {
   
      .bpx-dialog-accstatus-title {
        font-size: 1rem;
        font-weight: 400;
      }
  }
  .bpx-dialog-accstatus-content {
    text-align: center;
  }
  .bpx-dialog-accstatus-option-content {
    margin: 1rem 0 0.5rem 0;
  }
  .bpx-dialog-accstatus-buttons {
    margin-top: 1.5rem;
  }

}

.bpx-dialog-accstatus-warning {
  margin-top: 0.5rem;
  margin-left: 1rem;
  color: rgb(246, 158, 128);
}
.bpx-dialog-accstatus-datepicker {
  margin-top: 0.5rem;
}

.color-orange {
  color: rgb(246, 158, 128);
}

.bpx-select-item.color-orange {
  color: rgb(246, 158, 128);
}
.bpx-dialog-accstatus-blockline {

}

.bpx-dialog-accstatus-sectionline {
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 1rem;
}

.bpx-dialog-accstatus-leftline {
  width: 50%;
}

.bpx-dialog-accstatus-rightline {
  width: 50%;
}