.approval-history-arrow-container {
    display: flex;
    align-items: center;
  }

  .approval-history-line {
    width: 80px; 
    height: 2px;
    background-color: rgb(25, 118, 210); 
   
  }

.approval-history-arrow-right {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 8px solid rgb(25, 118, 210);
    
  }

  .grey.approval-history-content:nth-last-child(1) .approval-history-line{
    background-color: grey;
   
  }
  .grey.approval-history-content:nth-last-child(1) .approval-history-arrow-right {
     border-left: 8px solid grey
  }

  .approval-history-content-wrap{
    margin: 50px auto;

  }

  .approval-history-content, .approval-history-content-container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .approval-history-content svg{
    color: white;
  }

  .approval-history-content-icon{
    border: 1px solid rgb(25, 118, 210);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 35px;
    background-color: rgb(25, 118, 210);
    border-radius: 5px;
  }

.approval-history-content-number{
    width:26px;
    height: 26px;
    line-height: 26px;
    color: grey;
    border: 1px solid  grey;
    border-radius: 100%;
    text-align: center;
    vertical-align: middle;
  }