.bpx-modal-add-data {
  display: inline-block;
}

.bpx-modal-add-data-box {
  margin: 15px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}

.bpx-modal-add-data-field{
  width: 250px;
}
.bpx-modal-add-data-button-container{
  margin: 30px 0 0;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.bpx-modal-delete-item {
  font-size: 2.2rem !important;
  text-align: center;
}

.bpx-modal-add-data-autocomplete.MuiTextField-root,
.bpx-modal-add-data-autocomplete {
    width: 530px
}

.find-data .bpx-modal-add-data-autocomplete.MuiTextField-root,
.find-data .bpx-modal-add-data-autocomplete,
.find-data .bpx-modal-add-data-field{
    width: 450px
}

.bpx-support-changelog .bpx-modal-add-data-autocomplete.MuiTextField-root,
.bpx-support-changelog .bpx-modal-add-data-autocomplete{
  width: 100%;
}

.bpx-support-changelog .bpx-modal-add-data-autocomplete .MuiFormControl-root {
  width: 100%;
}

.jobs .bpx-modal-add-data-autocomplete.MuiTextField-root,
.jobs .bpx-modal-add-data-autocomplete {
    width: 790px
}


.bpx-modal-highlight{
  padding: 15px 0;
 // width: 100%;
  display: grid;
  grid-gap: 20px 30px;
  grid-template-columns: 1fr 1fr 1fr;
  //grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.bpx-modal-highlight-field{
  width: 200px;
}

@media only screen and (max-height: 1000px) {
    .bpx-modal-add-data-popup{
        overflow: scroll;
    }
  }
  @media only screen and (max-width: 850px) {
    .bpx-modal-highlight{
      grid-template-columns: 1fr 1fr;
    }
  }

  @media only screen and (max-width: 920px) {
    .jobs .bpx-modal-add-data-autocomplete.MuiTextField-root,
    .jobs .bpx-modal-add-data-autocomplete,
    .bpx-modal-add-data-autocomplete .MuiFormControl-root {
        width: 520px;
    }
  }

  @media only screen and (max-width: 650px) {
    .jobs .bpx-modal-add-data-autocomplete.MuiTextField-root,
    .jobs .bpx-modal-add-data-autocomplete,
    .bpx-modal-add-data-autocomplete .MuiFormControl-root {
        width: 250px;
    }
  }

  @media only screen and (max-width: 650px) {
    .bpx-modal-add-data-autocomplete.MuiTextField-root,
    .bpx-modal-add-data-autocomplete{
        width: 100%;
    }
    .bpx-modal-add-data-autocomplete .MuiFormControl-root{
        width: 100%;
    }
    .bpx-modal-highlight-field{
        width: 200px;
    }
    .bpx-modal-highlight{
      grid-template-columns: 1fr;
    }
  }

.MuiDialogActions-root>:not(:first-of-type) {
    margin-left: 15px !important;
}

.MuiDialogActions-root {
  padding: 20px !important
}

.jobs .bpx-modal-add-data{
  height: auto;
  align-self: self-start;
}

.jobs .bpx-modal-add-data-box{
  gap: 20px
}

.modal-rates-auto-complate .bpx-modal-add-data-autocomplete{
  width: 280px
}