.bpx-upload-modal {
  min-height: 450px;
  min-width: 550px;
  background-color: #fff;
  padding: 1rem;
}

.bpx-upload-header {
  text-align: center;
  position: relative;
  border-bottom: 2px solid #ccc;
  padding-bottom: 0.5rem;

    h3 {
      margin: 0;
    }
}

.bpx-upload-close {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;

    svg {
      color: silver;
    }
}

.bpx-upload-content {
  margin-top: 1rem;

  .subtotal-filter-label {
    font-size: 12px;
    font-family: "Arial Regular";
    color: #595959;
  }
  .toolbarSection {
    padding-top: 0.7rem;
  }

  .toolbarIconsBlock span.MuiButton-startIcon {
    z-index: inherit;
  }

  .toolbarIconsBlock button {
    height: 18.5px;
  }

}

.bpx-optionModal-section {

  label[for=file]:focus-within {
    outline: none;
  }
}
.bpx-upload-dragAndDrop {
  width: 360px;

  & > label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-width: initial;
    max-width: initial;
    text-align: center;
    background-color: #c8dadf;
    outline: 2px dashed #92b0b3;
    outline-offset: -5px;
    cursor: pointer;
    height: 180px;
  }

    

}

.bpx-upload-footer {
  margin-top: 2rem;
  text-align: right;
}

.bpx-upload-section {

    svg {
      fill: #92b0b3;
      height: 40px;
    }
    .bpx-text-file {
      padding-top: 0.2rem;
      font-size: 14px;
      font-family: "Arial Regular";
    
      span {
        font-weight: bold;
      }
    }

}

.bpx-upload-filename {
  margin-top: 1rem;

    .bpx-upload-list {
      display: flex;
      align-items: center;
    }
    .bpx-upload-icon {
      height: 23px;
      margin-right: 0.3rem;
    }
    .bpx-upload-icon svg {
      height: 23px;
    }
    .bpx-upload-name {
      font-family: "Arial Regular";
      font-size: 15px;
    }

}


.bpx-upload-content .bpx-wrap-loading {
  margin-top: 25%;
}

.bpx-upload-errors {
  color: red;
  font-size: 14px;
  font-family: "Arial Regular";
  margin-top: 0.3rem;
  padding-left: 0.3rem;
}


.bpx-simulation-modal {

  .MuiDialog-paper {
    width: 95%;
    margin: 1rem;
    max-width: initial;
  }
  .bpx-simulation-content {

    .tableContainer {
      padding-left: 0;
      padding-right: 0;
    }

  }
    
}

.bpx-simulation-header {
  width: 97%;
  margin: auto;
  border-bottom: 1px inset #000;
}

.bpx-simulation-content {

    .toolbarTitle {
      padding: 0;
      padding-bottom: 0.3rem;
      padding-top: 1.5rem;
      margin-bottom: 1rem;
      border-bottom: 2px solid #ccc;
    }
}
.bpx-simulation-footer {
  display: flex;
  justify-content: flex-end;

    button {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
      
    }
}

.block-title-simulation {
  font-weight: bold;
  font-size: 1.1rem;
  display: flex;
  margin-top: 1.3rem;
  
    .result-title-simulation {
      position: relative;
      margin-left: 0.3rem;
    }
    .icon-title-simulation {
      position: absolute;
      color: #4BB543;
      margin-left: 0.3rem;
    }

}



.bpx-simulation-modal {

  .bpx-upload-modal {
    min-height: initial;
  }
  .bpx-upload-close {
    right: -2%;
  }
}

.bpx-option-container {
  min-height: 200px;
  width: 450px;
  padding: 1rem;

    .bpx-option-header {
      position: relative;
    }
    .bpx-upload-close {
      position: absolute;
      right: -5px;
      top: -7px;
    }
    .bpx-option-content {
      text-align: center;
      margin-top: 50px;
      font-family: "Arial Regular";
    }
    .bpx-option-footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 50px;

        button {
          margin-right: 1rem;
        }
        button:last-child {
          margin-right: 0;
        }
        .MuiButton-contained {
          background-color: #ed7d31;
          color: black;
        }

    }
}

.bpx-error-bg-simulation {
  
    .MuiPaper-root.MuiPaper-elevation {
      max-height: initial;
      max-width: initial;
      width: 90%;    
      height: 300px;
    }
    .bpx-upload-modal {
      min-width: initial;
      min-height: initial;
    }
}
