$fontBread: 0.75rem;
$fontTitle: 0.875rem;

.toolbarborderBlock {

  &:after {
    content: '';
    border-bottom: 3px solid #e6e6e6;
    display: block;
  }
}

.toolbarBlock {
  display: flex;
  flex-flow: nowrap wrap;
  align-items: center;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
}
.toolbarSelectBU {
  padding-top: 1rem;
}
.toolbarborderBlock {
  position: relative;
}

.toolbarTitle {
  font-size: $fontTitle;
  font-weight: 500;
  padding: 1.375rem 0 1.375rem 10px;
  margin: 0;
  line-height: normal;
  font-family: "Montserrat", sans-serif;
}
.toolbarIconsBlock {
  display: flex;
  align-items: center;
}
.toolbarIconsBlock .toolbarIconSection {
  margin-right: 10px;
}



.toolbarIconsBlock button {
  font-size: $fontBread;
  font-family: "Montserrat";
  font-weight: 500;
  padding: 0;
  letter-spacing: normal;
  text-transform: capitalize;
  color: #000;
  border: 1px solid silver;
  border-radius: 3px;
  height: 18px;
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  user-select: none;
  vertical-align: middle;
  align-items: center;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

   &:hover {
    text-decoration: none;
    background-color: rgba(25, 118, 210, 0.04);
   }
}
.toolbarIconsBlock button:after {
  content: '';
  display: inline-block;
  margin-right: 1rem;
}

.toolbarIconsBlock span.MuiButton-startIcon{
  height: 16.5px;
  background-color: #f1f1f1;
  display: block;
  margin-left: 0;
  margin-right: 0;
  order: 2;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 0 2px 2px 0;
  z-index: -1;
}
.toolbarIconsBlock span.MuiButton-startIcon-closeAO{
  display: flex;
  justify-content: center;
  align-items: center;
}
.toolbarIconsBlock span.MuiButton-startIcon-closeAO img{
  height: 14px;
  padding: 1px 0 0 2px;
}
.toolbarGridColumnsButton svg {
  color: #1976d2;
}

.toolbarGridColumnsButton button[disabled],
.toolbarGridColumnsButton button[disabled] svg {
  color: rgba(0, 0, 0, 0.26);
  cursor: auto
}

.toolbarGridOrange svg {
  color: #e59073;
}

.ExportFontSize svg{
  font-size: 1rem !important;
}

.toolbarGridRed svg{
  color: #d32f2f;
}
.toolbarMoreHorizIcon svg {
  color: #3f8cd7;
}
.toolbarIconsBlock .toolbarMoreHorizIcon {
  border: none;
  height: 19px;
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  background-color: #f1f1f1;
  cursor: pointer;
  padding-left: 3px;
  padding-right: 3px;
}
.toolbarSection {
  padding: 1.25rem 0 0.9375rem 0;
}

.toolbarBlock {

   .block-switch {
      

        .MuiFormControlLabel-root {
          margin: 0;
          padding: 0;
          border: 1px solid silver;
          border-radius: 3px;
          height: 18px;
          padding-left: .37rem;
          padding-right: 0;
        }
        .MuiSwitch-root {
          height: 30px;
          width: 33px;
          padding-left: 0;
          padding-right: 0;
          margin-right: 3px;
        }
        .MuiSwitch-switchBase {
          left: -7px !important;

          &:hover {
            background-color: initial;
          }
        }
        .MuiSwitch-switchBase.Mui-checked {
          -webkit-transform: translateX(17px);
          -moz-transform: translateX(17px);
          -ms-transform: translateX(17px);
          transform: translateX(17px);
        }
   }
   .MuiTypography-root {
      color: #000;
      font-size: $fontBread;
      font-family: "Montserrat";
      font-weight: 500;
      letter-spacing: initial;
      text-transform: capitalize;
      margin-right: 1rem;
   }
}


.bpx-reference-keys {
  font-size: $fontTitle;
  font-weight: 500;
  // padding: 1.375rem 0 1.375rem 10px;
  margin: 0;
  line-height: normal;
  font-family: "Montserrat", sans-serif;

    .bpx-reference-text {
      margin-bottom: 1.5rem;
      text-align: right;
    }
    &:after {
      content: "";
      border-bottom: 3px solid #e6e6e6;
      display: block;
    }
}

.font-size  button{
  background: none;
	color: inherit;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.font-size span{
  padding: 2px;
  color: #7f7979;
}

.font-size .checked{
  text-decoration: underline;
}