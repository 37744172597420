.bpx-trade-header.MuiDataGrid-cell {
  background-color: #f1f1f1;
  justify-content: flex-start !important;
 position: relative;
 padding-right: 0 !important;
 
}

.bpx-trade-cell{
  padding: 0 !important;
  white-space: nowrap;
  overflow: hidden;
}

.trade-row-text-blue {
  color: #1976d2;
}

.bpx-trade-cell:focus-within {
  outline: none !important;
}

.padding0:focus{
  outline: 0,
}
.bpx-trade-header.MuiDataGrid-cell .MuiDataGrid-cellContent{
  display:flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.bpx-trade .MuiDataGrid-cellContent {
  width: 100%;
  height: auto;
  padding: 0;

}

.bpx-trade .MuiDataGrid-cellContent.right{
  text-align: right;
}

.bpx-trade .MuiDataGrid-cellContent.center{
  text-align: center;
}

.bpx-trade .MuiDataGrid-cellContent .trade-header-name{
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px 10px;
  height: 30px;
}

.bpx-trade .MuiDataGrid-cellContent .trade-main-row{
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 10px;
  height: 35px;
}

.bpx-trade .MuiDataGrid-cellContent .trade-ballance-row{
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px 10px;
  height: 30px;
}

.bpx-trade .MuiDataGrid-cellContent .trade-ballance-row.border{
  border-top: 1px solid rgb(224, 224, 224);
}

.bpx-trade-header.MuiDataGrid-cell .MuiDataGrid-cellContent .trade-header-name{
  width: calc(100% - 15px);
}

.bpx-trade .trade-header-separator{
  display: none;
}

.bpx-trade .bpx-trade-header .trade-header-separator{
  display: block;
  height: 28px;
  width: 14px;
  color: rgba(224, 224, 224, 1)
}

.bpx-trade  .bpx-trade-header .trade-header-separator svg {
  fill: currentColor;
   height: 28px;
    width: 14px;
}

.bpx-trade.MuiDataGrid-row--detailPanelExpanded {
  border-bottom: 0 !important;
}

.bpx-trade .MuiDataGrid-detailPanel {
  border: 0 !important
}



.bpx-trade .MuiDataGrid-cell {
  border-bottom: 0 !important
}


.bpx-trade .status {
  padding-left: 0 !important;
  padding-right: 0 !important;
  ;
}

.bpx-trade-left {
  border-left: 1px solid rgba(224, 224, 224, 1) !important
}

.bpx-trade-hierarchy .MuiDataGrid-cell {
  border-bottom: 0;
  border-top: 1px solid rgba(224, 224, 224, 1);
  //border-bottom:  1px solid rgba(224, 224, 224, 1)
}

.bpx-trade-top {
  border-top: 1px solid rgba(224, 224, 224, 1);
}

.bpx-trade-header-show-periods {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px !important;
}

.bpx-trade-icons {
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
}

.bpx-trade-icons.arrow {
  color: rgb(246, 158, 128)
}


.trade-bpx-detail {
  background: hsl(216deg 50% 94%);
  //font-size: 10px;
  margin: 0;
  padding: 10px 0 10px 50px;
  height: 32px;
  width: auto;
  font-style: italic;
}

.trade-bpx-detail ul {
  display: flex;
}

.trade-bpx-detail li {
  margin: 0 20px
}

.bpx-trade .active {
  color: rgb(0, 166, 81)
}

.bpx-trade .ended {
  color: rgb(246, 109, 56)
}

.bpx-trade .closed {
  color: rgb(246, 109, 56)
}

.bpx-trade .onhold {
  color: red
}

.trade-summary .red {
  color: red
}

.trade-summary .orange {
  color: rgb(246, 109, 56)
}

.bpx-trade .new {
  color: rgb(70, 139, 216)
}

.tradeToolbarIconsBlock {

  button:disabled,
  button[disabled] {
    color: grey;

    span .MuiSvgIcon-root {
      color: grey;
    }
  }
}

.tradeToolbarMenuItem {

  .MuiButtonBase-root.MuiButton-root,
  .text-menu-toolbar {
    font-size: 0.75rem !important;
    text-transform: capitalize !important;
    color: black !important;
  }

  .icon-menu-toolbar,
  .MuiButton-startIcon {
    color: #1976d2
  }
  .reset.icon-menu-toolbar {
      color: rgb(246,109,56)
    }
}


.trade-summary .bpx-modal-add-data {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
}

.trade-summary .MuiOutlinedInput-root {
  height: 27px;
  font-size: 12px;
}

.trade-summary .balance {
  font-weight: bold;
}

.trade-summary .data-grid-1 .plan,
.trade-summary .data-grid-1 .actual,
.trade-summary .data-grid-2 .total_amount {
  border-bottom: 0 !important
}

.trade-summary .data-grid-1 .actual{
 // padding-top: 1px;
}

.trade-summary .data-grid-1 .plan,
.trade-summary .data-grid-2 .total_amount {
  padding-top: 3px
}
.trade-summary .data-grid-1 .balance{
  padding-bottom: 2px
}

.trade-summary .data-grid-2 .percent_of_gsv {
  padding-bottom: 3px
}